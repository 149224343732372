<template>
  <div>
    <user-pages-wrapper title="Favorites">
      <div v-if="favorite_cars.length">
        <section ref="section2">
          <div class="px-3 my-5">
            <b-row
              class="match-height"
            >
              <b-col
                v-for="(car, i) in favorite_cars"
                :key="i"
                cols="12" 
                md="3"
              >
                <user-car-display-card 
                  :car="car" 
                  :is-favorite="true"
                  @refetchData="fetchFavoriteCars"
                />
              </b-col>
            </b-row>
          </div>
        </section>
      </div>
      <div v-else>
        <h3 class="text-dark">No cars in your favorites</h3>
      </div>
    </user-pages-wrapper>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BOverlay,
  BCardText,
  BJumbotron,
} from 'bootstrap-vue';

import { get } from "lodash";

import UserPagesWrapper from "@/@core/layouts/components/UserPagesWrapper.vue";
import UserCarDisplayCard from '@/@core/components/shared/UserCarDisplayCard.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    UserPagesWrapper,
    BRow,
    BCol,
    BImg,
    BLink,
    BOverlay,
    BCardText,
    BJumbotron,
    UserCarDisplayCard,
  },
  data() {
    return {
      loading: false,
      favorite_cars: []
    }
  },
  created(){
    this.fetchFavoriteCars()
  },
  methods: {
    async fetchFavoriteCars() {
      try {
        this.loading = true;

        const request = await this.useJwt().ristic.favoriteCars.list();
        const { data } = request.data;

        this.favorite_cars = data.map(this.makeCarDisplayData);
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    makeCarDisplayData(car) {
      const car_pricing = this.getValueFromSource(car, "car_pricing")
      const default_location = car_pricing.find(el => el.default_location)
      return {
        description: this.getValueFromSource(car, "car.car_description"),
        image: this.getValueFromSource(car, "car.car_images[0].image.path"),
        brand_title: this.getValueFromSource(car, "car.car_brand.title"),
        make_title: this.getValueFromSource(car, "car.car_make.title"),
        car_model_year: this.getValueFromSource(car, "car.car_model_year"),
        rate: default_location ? default_location.amount : 0,
        ...car.car,
      };
    },
  }
}
</script>

